
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.picture-outer {
  @include fluid(
    margin-top,
    (
      s: 80px,
      xl: 120px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );
}

.picture {
  position: relative;
  aspect-ratio: 64 / 21;
}

.picture__img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 2.4rem;
  object-fit: cover;
}
