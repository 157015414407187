
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































































































































































































































































.hero + .hero {
  background-color: $c-black-40;
}
