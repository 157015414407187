
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































.keypoints {
  position: relative;
  // margin-bottom: 5rem;
}

.keypoints__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  background-color: $c-dark-blue;
  border-radius: 4rem;
}

.keypoints__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 3rem;
  color: $c-white;

  @include mq($until: m) {
    align-items: center;
    border-bottom: 2px solid rgba($c-white, 0.1);

    > div {
      flex: 0 0 50%;
    }
  }

  @include mq(m) {
    flex-direction: column;
    width: calc(100% / 3);
    padding: 4rem 6rem 3rem;
    border-left: 1px solid rgba($c-white, 0.1);

    &:first-child {
      border-left: 0;
    }
  }
}

.keypoints__item__label {
  @extend %fw-medium;
  @include line-clamp();

  color: $c-light-blue;
}

.keypoints__item__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(m) {
    flex-direction: row;
    align-items: baseline;
  }
}

.keypoints__item__value {
  @include fluid(
    font-size,
    (
      xxs: 36px,
      xxl: 56px,
    )
  );

  margin-top: 0.5rem;
}

.keypoints__item__measure {
  @extend %ff-alt;

  font-size: 1.6rem;
  text-align: center;

  ::v-deep {
    p,
    span {
      font-size: 1.6rem !important;
    }
  }

  @include mq(m) {
    margin-left: 2rem;
    text-align: left;
  }
}
