
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.details {
  @include fluid(
    margin-top,
    (
      s: 48px,
      xl: 100px,
    )
  );

  margin-bottom: 10rem;
}

.details-inner {
  padding-right: col(1, 24);
  padding-left: col(1, 24);
}

.details__title {
  @extend %fw-normal;

  margin-bottom: $spacing;
  color: $c-dark-blue;
}

.details__collapses {
  margin-top: 6rem;
}

.details__content {
  ::v-deep {
    span {
      font-weight: 300 !important;
    }
  }
}
