
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































.product-card {
  position: relative;
  width: 100%;
  background-color: $c-black-20;
  border-radius: 24px;

  @include mq(m) {
    @include aspect-ratio(24, 32);
  }
}

.product-card-inner {
  overflow: hidden;
}

.product-card__container {
  @include fluid(
    padding,
    (
      s: 30px,
      xl: 48px,
    )
  );

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-card__headline {
  @extend %fw-medium;

  margin-top: auto;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
}

.product-card__title {
  @extend %fw-normal;

  color: $c-dark-blue;
}

.product-card__description {
  @include fluid(
    padding,
    (
      s: 30px,
      xl: 48px,
    )
  );
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );

  width: 100%;
  padding: 1.3rem 0 0;
  color: $c-excerpt;
  line-height: 3rem;
  letter-spacing: 0.5px;

  @include mq(m) {
    position: absolute;
    top: 3rem;
    left: 0;
    opacity: 0;
    transition: 0.5s $ease-out-quad;

    .product-card:hover & {
      top: 0;
      opacity: 1;
    }
  }
}

.product-card__picture-outer {
  @include aspect-ratio(1, 1);
  @include fluid(
    padding,
    (
      s: 30px,
      xl: 48px,
    )
  );

  order: -1;
  width: 100%;

  > * {
    display: flex;
    align-items: center;
  }

  @include mq(m) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.5s $ease-out-quad;

    .product-card:hover & {
      top: -3rem;
      opacity: 0;
    }
  }
}

.product-card__picture {
  @include fluid(
    margin,
    (
      s: 30px,
      xl: 65px,
    )
  );
}

.product-card__picture__picture {
  @include get-all-space;

  object-fit: cover;
}

.product-card__link {
  @include get-all-space;
}

.product-card__marquee {
  color: $c-black-50;
  font-size: 8vw;
  text-transform: uppercase;
  white-space: nowrap;
  animation: slide 10s linear infinite;
}

.product-card__ball {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 9.5rem;
  height: 9.5rem;
  border: 1px solid $c-black-50;
  border-radius: 50%;
  pointer-events: none;

  @include mq(m) {
    .active & {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(110%);
  }

  100% {
    transform: translateX(-110%);
  }
}
